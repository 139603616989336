import { Form, Input, InputNumber, Radio, Space, Switch } from "antd";
import gql from "graphql-tag";
import React, { Fragment } from "react";
import { useHistory, useParams } from "react-router-dom";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import { InputCountry } from "../Inputs";
import { DisableAutoRenew } from "./DisableAutoRenew";
import * as gd from "./__generated__/DeletePlan";
import * as g from "./__generated__/PlanGeneral";
import * as gu from "./__generated__/UpdatePlanGeneral";

const GET_PLAN_GENERAL = gql`
  query PlanGeneral($id: ID!) {
    plan(id: $id) {
      id
      name
      description
      countryCode
      active
      autoRenew
      vpnAccess
      properties {
        type
        operator
        proxyType
        maxSpeed
        ipPoolSize
        ipChangeDelay
      }
    }
  }
`;

const UPDATE_PLAN_GENERAL = gql`
  mutation UpdatePlanGeneral($id: ID!, $input: PlanUpdateInput!) {
    updatePlan(id: $id, input: $input) {
      id
      name
      description
      countryCode
      active
      autoRenew
      vpnAccess
      properties {
        type
        operator
        proxyType
        maxSpeed
        ipPoolSize
        ipChangeDelay
      }
    }
  }
`;

const DELETE_PLAN = gql`
  mutation DeletePlan($id: ID!) {
    deletePlan(id: $id)
  }
`;

export const EditGeneral = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();

  const { loading, data } = useQuery<g.PlanGeneral, g.PlanGeneralVariables>(GET_PLAN_GENERAL, {
    variables: { id },
  });

  const [updatePlan] = useMutation<gu.UpdatePlanGeneral, gu.UpdatePlanGeneralVariables>(UPDATE_PLAN_GENERAL, {
    okText: "Plan updated",
    refetchQueries: ["Plan"]
  });

  const [deletePort] = useMutation<gd.DeletePlan, gd.DeletePlanVariables>(DELETE_PLAN, {
    okText: "Plan deleted",
    onCompleted: (_) => {
      history.push("/plans");
    }
  });

  return (
    <Fragment>
      <h3>General</h3><br />
      <EditForm
        name="edit-plan-general"
        loading={loading}
        form={form}
        initialValues={data?.plan}
        onSave={(values) => updatePlan({ variables: { id, input: values } })}
        onDelete={() => deletePort({ variables: { id } })}
      >
        <Form.Item
          name="name"
          label="Name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="countryCode"
          label="Country"
        >
          <InputCountry />
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
        >
          <Input.TextArea rows={4} />
        </Form.Item>


        <Form.Item
          name="active"
          valuePropName="checked"
          label="Activation allowed"
        >
          <Switch />
        </Form.Item>

        <Form.Item label="Auto renewal allowed">
          <Space size="large">
            <Form.Item
              name="autoRenew"
              valuePropName="checked"
              noStyle={true}
            >
              <Switch />
            </Form.Item>
            <DisableAutoRenew name={data?.plan.name} />
          </Space>
        </Form.Item>

        <Form.Item
          name="vpnAccess"
          label="VPN access"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          name={["properties", "type"]}
          label="Type"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="private">Private</Radio.Button>
            <Radio.Button value="semi-private">Semi-Private</Radio.Button>
            <Radio.Button value="public">Public</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={["properties", "proxyType"]}
          label="Proxy type"
        >
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="mobile">Mobile</Radio.Button>
            <Radio.Button value="residential">Residential</Radio.Button>
            <Radio.Button value="server">Server</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name={["properties", "operator"]}
          label="Operator"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name={["properties", "maxSpeed"]}
          label="Max speed (mbit/s)"
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name={["properties", "ipPoolSize"]}
          label="IP pool size"
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name={["properties", "ipChangeDelay"]}
          label="IP change delay (s)"
        >
          <InputNumber />
        </Form.Item>

      </EditForm>
    </Fragment >
  );
};
