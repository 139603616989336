import { Form, Input, InputNumber, Select } from "antd";
import gql from "graphql-tag";
import React, { useState } from "react";
import * as gg from "../../__generated__/globalTypes";
import i18n from "../../common/i18n";
import { CreateForm } from "../../containers/CreateForm";
import { useMutation } from "../../hooks";
import * as g from "./__generated__/CreateModem";

const CREATE_MODEM = gql`
  mutation CreateModem($id: String!, $iface: String!, $proxyPort: String!, $webHost: String!, $externalIp: String!, $manufacturer: ModemManufacturer!) {
    createModem(id: $id, iface: $iface, proxyPort: $proxyPort, webHost: $webHost, externalIp: $externalIp, manufacturer: $manufacturer) {
      id
      iface
      proxyPort
      webHost
      webLogin
      webPassword
      externalIp
      manufacturer
      status
      wanIp
      createdAt
      updatedAt
    }
  }
`;

export const ModemCreate = () => {
  const [form] = Form.useForm<g.CreateModemVariables>();
  const [isOpen, toggle] = useState(false);

  const [createModem] = useMutation<{
    createModem: g.CreateModem,
    modem: g.CreateModem_createModem,
  }>(CREATE_MODEM, {
    okText: i18n.t("modem:Modem created"),
    refetchQueries: ["SearchModem"],
    onCompleted: (_) => {
      form.resetFields();
      toggle(false);
    },
  });

  return (
    <CreateForm
      name="create-modem"
      title={i18n.t("modem:Create modem")}
      form={form}
      initialValues={{ "manufacturer": gg.ModemManufacturer.huawei }}
      isOpen={isOpen}
      toggleOpen={() => toggle(!isOpen)}
      onCreate={(values) => createModem({ variables: values })}
    >
      <Form.Item
        name="id"
        label={i18n.t("modem:ID")}
        rules={[{ required: true }]}
      >
        <Input placeholder="UK-VPNCL01-101" />
      </Form.Item>
      <Form.Item
        name="manufacturer"
        label={i18n.t("modem:Manufacturer")}
        rules={[{ required: true }]}
      >
        <Select style={{ width: 150 }}>
          <Select.Option value="huawei">Huawei E3372</Select.Option>
          <Select.Option value="huawei525">Huawei B525</Select.Option>
          <Select.Option value="huaweiusa">Huawei USA</Select.Option>
          <Select.Option value="mikrotik">MikroTik</Select.Option>
          <Select.Option value="zte">ZTE</Select.Option>
          <Select.Option value="android">Android</Select.Option>
          <Select.Option value="quectel">Quectel</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="iface"
        label={i18n.t("modem:Interface")}
        rules={[{ required: true }]}
      >
        <Input placeholder="eth1.1001" />
      </Form.Item>
      <Form.Item
        name="proxyPort"
        label={i18n.t("modem:Proxy port")}
        rules={[{ required: true }]}
        normalize={(value) => String(value)}
      >
        <InputNumber placeholder="10001" min={1} max={65535} />
      </Form.Item>
      <Form.Item
        name="webHost"
        label={i18n.t("modem:Web interface host")}
        rules={[{ required: true }]}
      >
        <Input placeholder="192.168.101.1:80" />
      </Form.Item>
      <Form.Item
        name="externalIp"
        label={i18n.t("modem:External IP")}
        rules={[{ required: true }]}
      >
        <Input placeholder="172.16.21.22" />
      </Form.Item>
    </CreateForm>
  );
};
