import { Button, Card, Form, Input, InputNumber, Select, Space, Typography, List } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import gql from "graphql-tag";
import React, { useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../common/i18n";
import { EditForm } from "../../containers/EditForm";
import { useMutation, useQuery } from "../../hooks";
import * as gd from "./__generated__/DeleteNode";
import * as g from "./__generated__/Node";
import * as gr from "./__generated__/SearchPowerResetCommand";
import * as gs from "./__generated__/SyncNode";
import * as gu from "./__generated__/UpdateNode";

const GET_NODE = gql`
  query Node($id: ID!) {
    node(id: $id) {
      id
      ipLastOctet
      defaultMikrotikLogin
      defaultMikrotikPassword
      defaultMikrotikResetCommand
      heartbeat {
        spoofer
        exporter
      }
      iProxy
      iProxyBurrowed {
        id
        uri
      }
      localtonet
      localtonetKey
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_NODE = gql`
  mutation UpdateNode($id: ID!, $input: NodeUpdateInput!) {
    updateNode(id: $id, input: $input) {
      id
      ipLastOctet
      defaultMikrotikLogin
      defaultMikrotikPassword
      defaultMikrotikResetCommand
      heartbeat {
        spoofer
        exporter
      }
      iProxy
      iProxyBurrowed {
        id
        uri
      }
      localtonet
      localtonetKey
      createdAt
      updatedAt
    }
  }
`;

const DELETE_NODE = gql`
  mutation DeleteNode($id: ID!) {
    deleteNode(id: $id)
  }
`;

const SEARCH_POWER_RESET_COMMAND = gql`
  query SearchPowerResetCommand($input: SearchInput!) {
    searchMikrotikCommand(input: $input) {
      data {
        name
        command
      }
    }
  }
`;

const SYNC_NODE = gql`
  mutation SyncNode($id: ID!, $kind: String!, $key: String, $burrowed: [NodeUpdateBurrowedInput!]) {
    syncNode(id: $id, kind: $kind, key: $key, burrowed: $burrowed)
  }
`;

const gridStyle = {
  width: "50%",
  outline: "none",
};

interface DefaultResetSelectorState {
  name: string;
  filter: string;
}

const defaultResetSelectorReducer = (
  state: DefaultResetSelectorState,
  action: { type: string, data: any }
) => {
  switch (action.type) {
    case "INIT":
      return { ...state, name: action.data.defaultMikrotikResetCommand };
    case "FILTER":
      return { ...state, filter: action.data };
    case "SELECT":
      return { ...state, name: action.data };
    default:
      throw new Error(`undefined action ${action.type} `);
  }
};


interface NodeEditFields {
  id: string;
  ipLastOctet: number;
  iProxyBurrowed: { id: string; uri: string; }[];
}

export const NodeEdit = () => {
  const { t } = useTranslation("node");

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [baseForm] = Form.useForm<NodeEditFields>();
  const [mikrotikForm] = Form.useForm<NodeEditFields>();

  const { loading, data } = useQuery<g.Node, g.NodeVariables>(GET_NODE, {
    variables: { id },
    onCompleted: (data) => {
      dispatch({ type: "INIT", data: data.node });
      setLocaltonetKey(data.node.localtonetKey);
      setBurrowedConnections(data.node.iProxyBurrowed || []);
    }
  });

  const [updateNode] = useMutation<gu.UpdateNode, gu.UpdateNodeVariables>(UPDATE_NODE, {
    okText: t("Node updated"),
    refetchQueries: ["Node"]
  });

  const [deleteNode] = useMutation<gd.DeleteNode, gd.DeleteNodeVariables>(DELETE_NODE, {
    okText: t("Node deleted"),
    onCompleted: (_) => {
      history.push("/nodes/list");
    }
  });

  const [syncResult, setSyncResult] = useState("");
  const [syncNode, syncStatus] = useMutation<gs.SyncNode, gs.SyncNodeVariables>(SYNC_NODE, {
    okText: t("Node synced"),
    refetchQueries: ["Node"],
    onCompleted: (r) => {
      setSyncResult(r.syncNode);
    }
  });

  const [state, dispatch] = useReducer(defaultResetSelectorReducer, { name: "", filter: "" });

  const { "data": commandData } = useQuery<gr.SearchPowerResetCommand, gr.SearchPowerResetCommandVariables>(SEARCH_POWER_RESET_COMMAND, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        filter: state.filter,
        page: 1,
        pageSize: 0,
      }
    }
  });

  const [localtonetKey, setLocaltonetKey] = useState("");
  const [burrowedConnections, setBurrowedConnections] = useState<{ id: string; uri: string; }[]>([]);
  const [newConnection, setNewConnection] = useState<{ id: string; uri: string; }>({ id: "", uri: "" });
  const [isAdding, setIsAdding] = useState(false);

  const validateSocks5Uri = (uri: string): boolean => {
    try {
      const url = new URL(uri);
      
      // Check protocol
      if (url.protocol !== "socks5:") {
        return false;
      }

      // Check if we have username and password
      if (!url.username || !url.password) {
        return false;
      }

      // Check if we have host and port
      if (!url.hostname || !url.port) {
        return false;
      }

      // Validate port number
      const port = parseInt(url.port, 10);
      if (isNaN(port) || port < 1 || port > 65535) {
        return false;
      }

      return true;
    } catch {
      return false;
    }
  };

  const addBurrowedConnection = () => {
    if (validateSocks5Uri(newConnection.uri) && newConnection.id.trim()) {
      setBurrowedConnections([...burrowedConnections, { id: newConnection.id, uri: newConnection.uri }]);
      setNewConnection({ id: "", uri: "" });
      setIsAdding(false);
    }
  };

  const removeBurrowedConnection = (id: string) => {
    setBurrowedConnections(burrowedConnections.filter(conn => conn.id !== id));
  };

  return (
    <Card title={data?.node.id} bordered={false} headStyle={{ padding: "0" }} bodyStyle={{ padding: "24px 0" }}>
      {data?.node.iProxy &&
        <Card.Grid style={{ width: "100%" }} hoverable={false}>
          <h3>{t("iProxy")}</h3><br />
          <Space direction="vertical" style={{ width: "100%" }}>
            <Button type="primary" loading={syncStatus.loading} 
              onClick={() => syncNode({ 
                variables: { 
                  id, 
                  kind: "iProxy", 
                  burrowed: burrowedConnections 
                } 
              })}
            >
              {t("Sync iProxy connections")}
            </Button>
            
            <div style={{ marginTop: "8px" }}>
              {!isAdding ? (
                <Button 
                  type="dashed" 
                  onClick={() => setIsAdding(true)}
                  icon={<PlusOutlined />}
                  size="small"
                >
                  Add shared connection override
                </Button>
              ) : (
                <Space direction="vertical" size={4}>
                  <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                    <Input
                      value={newConnection.id}
                      onChange={(e) => setNewConnection({ ...newConnection, id: e.target.value })}
                      placeholder="ID"
                      style={{ width: "120px" }}
                      size="small"
                    />
                    <Input
                      value={newConnection.uri}
                      onChange={(e) => setNewConnection({ ...newConnection, uri: e.target.value })}
                      status={newConnection.uri === "" || validateSocks5Uri(newConnection.uri) ? "" : "error"}
                      placeholder="socks5://host:port"
                      style={{ flex: 1, width: "300px" }}
                      size="small"
                    />
                    <Button 
                      type="primary"
                      size="small"
                      disabled={!validateSocks5Uri(newConnection.uri) || !newConnection.id.trim()}
                      onClick={addBurrowedConnection}
                    >
                      Add
                    </Button>
                    <Button 
                      size="small"
                      onClick={() => {
                        setIsAdding(false);
                        setNewConnection({ id: "", uri: "" });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Space>
              )}
              
              <List
                size="small"
                dataSource={burrowedConnections}
                style={{ marginTop: "8px" }}
                renderItem={item => (
                  <List.Item style={{ padding: "4px 0" }}>
                    <div style={{ display: "flex", gap: "8px", alignItems: "center", width: "100%" }}>
                      <Button 
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        size="small"
                        onClick={() => removeBurrowedConnection(item.id)}
                      />
                      <Typography.Text type="secondary" style={{ width: "100px", fontSize: "12px" }}>{item.id}</Typography.Text>
                      <Typography.Text style={{ flex: 1, fontSize: "12px" }}>{item.uri}</Typography.Text>
                    </div>
                  </List.Item>
                )}
              />
            </div>
            
            <Typography.Text>{syncResult}</Typography.Text>
          </Space>
        </Card.Grid>
      }

      {data?.node.localtonet &&
        <Card.Grid style={{ width: "100%" }} hoverable={false}>
          <h3>{t("LocaltoNet")}</h3><br />
          <Space>
            <Typography.Text>API Key:</Typography.Text>
            <Input
              style={{ width: "420px" }}
              name="localtonetKey"
              value={localtonetKey}
              onChange={(e) => setLocaltonetKey(e.target.value)}
            />
          </Space>
          <br /><br />
          <Button type="primary" loading={syncStatus.loading} onClick={() => syncNode({ variables: { id, kind: "localtonet", key: localtonetKey } })}>{t("Sync LocaltoNet connections")}</Button>
          <br /><br />
          <Typography.Text>{syncResult}</Typography.Text>
        </Card.Grid>
      }

      <Card.Grid style={gridStyle} hoverable={false}>
        <h3>{t("Base options")}</h3><br />

        <EditForm
          name="edit-node-base"
          deleteTitle={t("Delete node?")}
          loading={loading}
          form={baseForm}
          initialValues={data?.node}
          onSave={(values) => updateNode({ variables: { id, input: values } })}
          onDelete={() => deleteNode({ variables: { id } })}
        >
          <Form.Item
            name="id"
            label={t("ID")}
          >
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="ipLastOctet"
            label={t("IP Last Octet")}
          >
            <InputNumber min={0} max={255} />
          </Form.Item>
        </EditForm>
      </Card.Grid>

      <Card.Grid style={gridStyle} hoverable={false}>
        <h3>{t("Default MikroTik options")}</h3><br />
        <EditForm
          name="edit-node-mikrotik"
          loading={loading}
          form={mikrotikForm}
          initialValues={data?.node}
          onSave={(values) => updateNode({ variables: { id, input: { ...values, defaultMikrotikResetCommand: state.name } } })}
        >
          <Form.Item
            name="defaultMikrotikLogin"
            label={i18n.t("Login")}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="defaultMikrotikPassword"
            label={t("Password")}
          >
            <Input />
          </Form.Item>
          <Form.Item label={t("Command")}>
            <Select
              value={state.name}
              placeholder={t("Select command")}
              filterOption={false}
              showSearch={true}
              onSearch={(val) => dispatch({ type: "FILTER", data: val })}
              onSelect={(val: string) => dispatch({ type: "SELECT", data: val })}
              style={{ width: "100%" }}
            >
              {commandData?.searchMikrotikCommand.data.map(d => <Select.Option key={d.name} value={d.name}>{d.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </EditForm>
      </Card.Grid>
    </Card>
  );
};
